@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
  scroll-padding-top: 8.5rem;

}


body{
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  background-color: #fff;
}

@layer components{
  .wrapper{
    @apply flex justify-center ;
  }

  .container {
    @apply max-w-[1440px]  px-[16px] md:px-[48px] lg:px-[100px];
  }

  .clickable{
    @apply cursor-pointer hover:opacity-80
  }

  .text-h1{
    @apply font-bold text-[38px] leading-[45px] lg:text-[58px] lg:leading-[85px] font-noto
  }
  .text-h2{
    @apply font-bold text-[34px] leading-[46px] lg:text-[54px] lg:leading-[73px] font-noto
  }

  .text-20-to-24{
    @apply text-[20px] lg:text-[24px] leading-[27px] lg:leading-[32px]
  }
  .text-18-to-24{
    @apply text-[18px] lg:text-[24px] leading-[24px] lg:leading-[32px]
  }

  .text-14-to-18{
    @apply text-[14px] lg:text-[18px] leading-[19px] lg:leading-[24px] font-normal
  }

  .btn-primary{
    @apply flex gap-1 py-2 px-[14px] rounded-[2px] hover:cursor-pointer text-primary hover:text-action hover:shadow-btn-hover shadow-btn transition-all duration-200;
    
  }

  .nav-shadow{
    box-shadow: 0px 4px 0px 0px #01B3BF;

    box-shadow: 0px 12px 14px 0px #0F474B26;

  }
  .hiw-item-gradient{
    background: linear-gradient(180deg, rgba(9, 16, 27, 0) 30.17%, #09101B 100%);
    
  }
  .hiw-item-gradient-mob{
    background: linear-gradient(270deg, rgba(9, 16, 27, 0) 0%, #09101B 100%);

    
  }
}


.nav-gradient{
  background: linear-gradient(180deg, rgba(9, 16, 27, 0.85) 0%, rgba(9, 16, 27, 0) 100%);
}

.transform-rotate{
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}